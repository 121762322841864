<div appClickOutside
     class="ex-dropdown-container"
     [ngClass]="{'xs': size === 'x-small', 'sm': size === 'small', 'md': size === 'medium', 'black': blackStyle,
     'chevron-d-hover': chevronVisibility === 'hover', 'position-relative': container === 'container', 'transparent': transparentStyle}"
     (clickOutside)="clickOutside()"
     includeOverlay
>
  <div #dropdownHeader class="ex-dropdown-header"
       cdkOverlayOrigin #trigger="cdkOverlayOrigin"
       [ngClass]="{'flat-left': flatSide === 'left', 'flat-right': flatSide === 'right', 'shadow-border': shadowBorder}"
       [style.background-color]="headerBackgroundColor"
       [style.color]="headerColor"
       [style.border]="noBorder ? 'unset' : undefined"
       (click)="toggle()">
    <div *ngIf="icon && !replaceChevron" class="header-extreme">
      <img [src]="icon" class="dropdown-icon filter-basic-600" alt="dropdown-icon"/>
    </div>
    <ng-container *ngIf="!(displayTemplate && noInput)">
      <label *ngIf="label" [for]="input" class="ex-dropdown-label">{{label}}</label>
      <input #input class="ex-dropdown-input"
             [ngClass]="{'fw-semibold': boldInputText}"
             type="text"
             autocomplete="false"
             [readonly]="noInput"
             [placeholder]="placeholder || ''"
             [style.width.px]="inputWidth"
             [(ngModel)]="text"
             (ngModelChange)="update($event); toggle(true)"
      />
    </ng-container>
    <div *ngIf="displayTemplate && noInput" class="ex-dropdown-input">
      <ng-container [ngTemplateOutlet]="displayTemplate.template" [ngTemplateOutletContext]="{$implicit: text}"/>
    </div>

    <img *ngIf="text && !noInput" class="clear" src="/assets/images/icon-close-circle.svg" alt="icon-close"
         (click)="clear()">
    <div *ngIf="!noChevron" class="header-extreme">
      <div class="chevron" [style.color]="headerColor || 'var(--basic-500)'">
        <img *ngIf="icon && replaceChevron" [src]="icon" class="dropdown-icon filter-basic-600" alt="dropdown-icon"/>
        <i *ngIf="!replaceChevron" [hidden]="!show" class="ph ph-caret-up"></i>
        <i *ngIf="!replaceChevron" [hidden]="show" class="ph ph-caret-down"></i>
      </div>
    </div>
  </div>

  <ng-container *ngIf="container === 'container'" [ngTemplateOutlet]="dropdownMenu" />
  <ng-template
    #dropdownMenu
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="container === 'body' && show"
    [cdkConnectedOverlayPositions]="overlayPositions"
    (attach)="disableScroll()"
    (detach)="enableScroll()"
  >
    <div [hidden]="!show" class="ex-dropdown-menu shadow-sm {{dropdownMenuClass}}"
         [ngClass]="{'strict-width': strictWidth, 'left': placement==='left', 'right': placement==='right',
                     'overlaid': container === 'body', 'large-border': largeBorder}"
    >

      <!--content-->
      <div class="d-flex flex-column" aria-labelledby="dropdownList"
           [ngClass]="{'content-with-footer': footerTemplate}">
        <ng-container *ngIf="contentTemplate" [ngTemplateOutlet]="contentTemplate.template"/>
        <div #itemsContainer *ngIf="!contentTemplate && items" class="items-container"
             [ngClass]="{'padded': paddedItemsContainer}">
          <button *ngFor="let item of filteredItems; let i = index"
                  class="ex-dropdown-item"
                  [ngClass]="{'focused': focusIndex == i}"
                  (click)="select(item)"
                  [disabled]="isDisabled(item)"
          >
            <ng-container *ngIf="itemTemplate" [ngTemplateOutlet]="itemTemplate.template"
                          [ngTemplateOutletContext]="{$implicit:item}"/>
            <div *ngIf="!itemTemplate" class="default-item">{{ item.label }}</div>
          </button>
        </div>
      </div>

      <!--footer-->
      <div [hidden]="!show" *ngIf="footerTemplate" class="dropdown-footer">
        <ng-container *ngIf="footerTemplate" [ngTemplateOutlet]="footerTemplate.template"/>
      </div>

    </div>
  </ng-template>

</div>
