<div class="tender-filter-container d-flex align-items-center" [ngClass]="{'loading-filters': !userDataLoaded}">

  <!--case text search enabled, ie. in tender search view-->
  <div class="d-flex multi-search-filters-container">
    <ng-container *ngIf="textSearchEnabled; else onlyTopicsSearch">
      <!--selector between 2 search modes : topics or text-->
      <ex-dropdown noInput
                   inputText="{{'tenders.search-mode.' + activeModeSignal() | translate | uppercase}}"
                   class="dropdown-container"
                   size="small"
                   flatSide="right"
                   shadowBorder
                   [forceToggle$]="forceToggleMap.get(ForceToggleEnum.SEARCH_TYPE)"
      >
        <ng-template exDropdownContent>
          <div class="search-mode-selector no-select">
            <div *ngFor="let mode of SearchMode | keyvalue | sort : 'value' : true"
                 class="search-mode-content d-flex w-100"
                 (click)="updateActiveMode(mode.value)">
              <div class="d-flex align-items-center">
                <span class="mode-text">{{ "tenders.search-mode." + mode.value | translate | uppercase }}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </ex-dropdown>
      <!--case topics-->
      <ng-container *ngIf="activeModeSignal() === 'topics'; else textSearchInput">
        <app-filter-topic *ngIf="!filters || filters.includes(FilterType.TOPIC)"
                          class="multi-search-view"
                          [items]="tenderTopics || []"
                          [defaultTopicIds]="defaultTopicIds"
                          [forceToggle$]="forceToggleMap.get(ForceToggleEnum.TOPICS)"
                          (itemsSelected)="topicsSelected($event)"
                          multiSearchView
        />
      </ng-container>
      <!--case text-->
      <ng-template #textSearchInput>

        <div class="text-input-container">
          <input type="text"
                 id="tenders-search-text-input"
                 [ngClass]="{'pdr-24px': (searchText.length > 0)}"
                 class="text-search-input flex-grow-1"
                 [(ngModel)]="searchText"
                 (ngModelChange)="sanitizeText()"
                 (keyup.enter)="onTextSearch()"
                 [placeholder]="'tenders.text-filter-placeholder' | translate"/>
          <i *ngIf="searchText.length"
               class="ph ph-x-circle clear-text-button d-flex align-items-center justify-content-center"
               (click)="searchText = ''; onTextSearch()">
          </i>
        </div>

        <div class="search-button d-flex align-items-center justify-content-center cursor-pointer"
             (click)="onTextSearch()"
             [ngClass]="{'disabled': !searchText}">
          <img class="icon-18" src="assets/images/magnifying-glass-white.svg" alt="search-icon"/>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <!--case only topics, ie. in market watch views-->
  <ng-template #onlyTopicsSearch>
    <app-filter-topic
      *ngIf="!filters || filters.includes(FilterType.TOPIC)"
      [items]="tenderTopics || []"
      [defaultTopicIds]="defaultTopicIds"
      (itemsSelected)="topicsSelected($event)"
    />
  </ng-template>

  <!--other filters are common-->
  <app-filter-date
    *ngIf="!filters || filters.includes(FilterType.PERIOD)"
    (periodSelected)="periodSelected($event)"
  />
  <div *ngIf="!noLabel" class="filters-label">{{ 'tenders.filters' | translate }}</div>
  <app-filter-territory
    *ngIf="!filters || filters.includes(FilterType.TERRITORY)"
    [items]="territoryItems"
    [defaultTerritories]="defaultTerritoriesIds"
    [alternativeDisplay]="alternativeDisplay"
    [selectionCount]="territorySelectionCount"
    [maxCount]="totalCount"
    (territoriesSelected)="territoriesSelected($event)"
  />
  <app-filter-type
    *ngIf="!filters || filters.includes(FilterType.TYPE)"
    [defaultTypes]="defaultTypes"
    (typesSelected)="typesSelected($event)"
  />
</div>
