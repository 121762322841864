import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TendersSearchService } from '../../services/tenders-search.service';
import { DisplayItem, DropdownItem } from '../../../common-explain/components/ex-dropdown/ex-dropdown.component';
import { StatusItem } from '../../models/tender-status';

@Component({
  selector: 'app-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.scss']
})
export class FilterStatusComponent implements OnChanges {
  @Input({required: true}) tendersSearchService!: TendersSearchService;
  @Input() items: DisplayItem<StatusItem>[] = [];
  private _selectedItems: DisplayItem<StatusItem>[] = [];
  unSelectedItems: DisplayItem<StatusItem>[] = [];
  defaultStatuses = ['opportunite', 'a_creuser', 'pour_info', 'go'];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      this.selectedItems = this.items
        .filter((item) => this.tendersSearchService.selectedStatusIds?.includes(item.value.id))
        .filter((item) => this.defaultStatuses.includes(item.value.name ?? ''));
    }
  }

  countOf(status: DisplayItem<StatusItem>) {
    return this.tendersSearchService.statusCounts?.find((s) => s.statusId === status.value.id)?.count ?? undefined;
  }

  removeStatus(id: number) {
    if (this.tendersSearchService.selectedStatusIds) {
      this.selectedItems = this.selectedItems.filter((item) => item.value.id !== id);
      this.tendersSearchService.selectedStatusIds = this.selectedItems.map(item => item.value.id);
      if (this.tendersSearchService.selectedStatusIds.length === 0) {
        this.tendersSearchService.selectedStatusIds = this.items.map(item => item.value.id);
      }
    }
  }
  addStatus(event: DropdownItem | null) {
    if (event) this.selectedItems = this.selectedItems.concat(event);
  }

  get selectedItems(): DisplayItem<StatusItem>[] {
    return this._selectedItems;
  }

  set selectedItems(value: DisplayItem<StatusItem>[]) {
    this._selectedItems = this.items.filter((item) => value.map(item => item.value.id).includes(item.value.id));
    this.unSelectedItems = this.items.filter((item) => !this._selectedItems.map(item => item.value.id).includes(item.value.id));
    this.tendersSearchService.selectedStatusIds = this._selectedItems.map(item => item.value.id);
  }
}
