<ex-dropdown *ngIf="context === 'in-list-view'; else wideNoteEditor"
   #narrowNoteEditor id="narrowNoteEditor"
  class="ls-m1"
  size="x-small"
  noInput
  noChevron
  noBorder
  ignoreEnterKey
  [headerBackgroundColor]="displayText ? '' : 'var(--basic-250)' "
  [forceToggle$]="forceToggle$"
  (dropdownToggle)="onDropdownToggle($event)"
  (outsideClicked)="clickOutside($event)"
  container="body"
  [overlayPositions]="[
    {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
    {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'}
  ]"
>

  <ng-template exDropdownDisplay>

    <div class="d-flex w-100 align-items-center justify-content-evenly">
      <i class="{{dbNote ? 'ph-fill' : 'ph'}} ph-notepad"></i>
      <span class="note-placeholder" *ngIf="displayText && !isPanelOpen">{{'tenders.note.note-placeholder' | translate}}</span>
    </div>

  </ng-template>

  <ng-template exDropdownContent>
    <div class="d-flex flex-column note-editor">
      <textarea #narrowNoteTextArea id="narrowNoteTextArea"
        [(ngModel)]="note" placeholder="Ecrivez votre note ici..."
                class="border-radius-8px"
                cols="33"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                maxlength="300"
                (click)="mode = 'edition'"
      ></textarea>
      <div class="footer-part overflow-hidden flex-center-end">
        <ng-container *ngTemplateOutlet="editionButton; context: {template: narrowNoteTextArea}"/>
      </div>
    </div>
  </ng-template>

</ex-dropdown>


<ng-template #wideNoteEditor id="wideNoteEditor">
  <div class="note-container" [style.min-height.px]="note ? 80 : 0">
    <div class="title">
      <i class="ph-fill ph-notepad"></i>
      <span class="fw-700">{{ 'tenders.note.note' | translate | uppercase }}</span>
    </div>
    <textarea #wideNoteTextArea id="wideNoteTextArea"
              (focus)="getNote()"
              (blur)="$event.stopPropagation(); updateNote()"
              placeholder="Ecrivez votre note ici..."
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
              maxlength="300"
              (click)="mode = 'edition'"
              (ngModelChange)="note$.next(note)"
              [(ngModel)]="note">
    </textarea>
  </div>
</ng-template>

<ng-template #editionButton let-template=template>
  <div class="btn-container flex-center-end">
    <button class="textarea-button outline-and-rounded"
            (click)="$event.stopPropagation(); mode === 'edition' ? updateNote() : template.focus(); mode = 'edition'">
      <div class="flex-center-center">
        <span *ngIf="mode === 'edition'">{{ 'tenders.note.save' | translate }}</span>
        <span *ngIf="mode === 'view'">{{ 'tenders.note.edit' | translate }}</span>
      </div>
    </button>
  </div>
</ng-template>
